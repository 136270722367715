const load = async (path) => {
  const res = await fetch(path);
  const raw = await res.text();
  return raw;
}

// file must have 3 headers in the first row: name, email, phone - in that order
// each subsequent row must be separated by a newline character: \n
// each column within a row must be separated by a comma character: ,
const parse = async (raw) => {
  const rows = [];
  let row = [];
  let valStart = 0;
  let valEnd = 0;
  let i = 0;
  let insideQuote = false;
  while (i < raw.length) {
    const c = raw[i];
    if (!insideQuote && c === '"') {
      insideQuote = true;
      i++;
      valStart = i;
    } else if (insideQuote && c === '"') {
      insideQuote = false;
      valEnd = i;
      i++;
    } else if (c === "," && !insideQuote) {
      const val = raw.slice(valStart, valEnd);
      row.push(val);
      i++;
      valStart = i;
      valEnd = i;
    } else if (c === "\n" && !insideQuote) {
      const val = raw.slice(valStart, valEnd);
      row.push(val);
      rows.push(row);
      row = [];
      i++;
      valStart = i;
      valEnd = i;
    } else {
      i++;
      valEnd = i;
    }
  }
  return rows;
}

const validate = async (csv) => {
  const headers = csv[0];
  const rows = csv.slice(1);

  if (
    headers.length !== 3 ||
    headers[0].toLowerCase() !== 'name' ||
    headers[1].toLowerCase() !== 'email' ||
    headers[2].toLowerCase() !== 'phone'
  )
    throw new Error("Invalid headers, expected: name, email, phone")

  rows.forEach(([name, email, phone]) => {
    if (!name)
      throw new Error("Invalid row: missing name")
  })

  return rows;
}

export const parseCSV = async(path) => {
  const raw = await load(path);
  const csv = await parse(raw);
  const rows = validate(csv);
  return rows;
}
