import React, {useEffect, useState} from "react";
import {Button} from "../atoms/Button";
import SvgLogout from "../icons/SvgLogout";
import SvgUserCard from "../icons/SvgUserCard";
import SvgLogoMark from "../atoms/SvgLogoMark";
import SelectComponent from "../atoms/SelectComponent";
import {signOut} from '../../api/auth';
import {connect} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {useLocation} from "react-use";
import SvgHome from "../icons/SvgHome";
import SvgUserPlus from "../icons/SvgUserPlus";
import CreateEventPaywallModal from '../modals/CreateEventPaywallModal';

const mapStateToProps = (state) => {
    return {
        authSession: state.userReducer.authSession,
        userProfile: state.userReducer.userProfile,
        userEvents: state.userReducer.userEvents,
        currentEventId: state.userReducer.currentEventId,
        currentEventView: state.userReducer.currentEventView,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setEventView: (view) => {
            dispatch({
                type: "SET_EVENT_VIEW",
                view
            })
        }
    }
}

function Navbar(props) {
    const navigate = useNavigate();
    const location = useLocation()
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventOptions, setEventOptions] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);
    const [paywallModalOpen, setPaywallModalOpen] = useState(false);

    useEffect(() => {
        setAuthenticated(!!props.authSession)
    }, [props.authSession])

    useEffect(() => {
        const events = Object.keys(props.userEvents).reduce((arr, k) => [...arr, ...[
            {
                id: props.userEvents[k].id,
                title: props.userEvents[k].title,
                position: props.userEvents[k].organizers?.map(o => o.profile_id).includes(props.authSession.user.id) ? "Organizer" : null

            },
            {
                id: props.userEvents[k].id,
                title: props.userEvents[k].title,
                position: props.userEvents[k].hosts?.map(o => o.profile_id).includes(props.authSession.user.id) ? "Host" : null
            },
            {
                id: props.userEvents[k].id,
                title: props.userEvents[k].title,
                position: props.userEvents[k].guests?.map(o => o.profile_id).includes(props.authSession.user.id) ? "Guest" : null
            },
        ].filter(e => !!e.position).map(e => ({...e, title: `[${e.position}] ${e.title}`}))
        ], [])
        setEventOptions(events)
        setSelectedEvent(events
            .filter(e => e.id === props.currentEventId)
            .filter(e => !!props.currentEventView ? props.currentEventView === e.position : true)
            ?.[0]?.title
        )
    }, [props.userEvents, props.currentEventId])

    const handleEventChange = (eventName) => {
        setSelectedEvent(eventName)
        const _event = eventOptions.filter(e => e.title === eventName)[0];
        const eventId = _event.id;
        const view = _event.position;
        props.setEventView(view);
        navigate(`/event/${eventId}`)
    }


    if (location.pathname === '/auth' || location.pathname === '/onboarding' || location.pathname === '/verify' || location.pathname === '/create-event') {
        return null
    }
    return (
        <div className='h-14 fixed w-screen flex flex-col z-50'>
            <div
                className='py-2 px-4 fixed h-14 bg-white md:px-8  z-[12] border-b border-gray-100 flex flex-row items-center justify-center w-full mx-auto'>
                <div className='flex flex-row items-center justify-between w-full max-w-[1370px]'>
                    <Link to={'/'}>
                        <SvgLogoMark className={'w-36 md:w-40 h-9'}/>
                    </Link>
                    {authenticated ?
                        <div className='flex flex-row items-center gap-3'>
                            {(eventOptions.length > 0 && location.pathname !== '/') &&
                              <div className='flex-row items-center md:gap-2 hidden sm:flex'>
                                  <p className='text-sm text-gray-500 font-medium hidden md:block'>
                                      Event:
                                  </p>
                                  <SelectComponent
                                      label={'Select an event'}
                                      value={selectedEvent}
                                      setValue={handleEventChange}
                                      options={eventOptions}
                                  />
                              </div>
                            }
                            {location.pathname === '/' && (
                                (Object.keys(props.userEvents).filter(k => props.userEvents[k].owner_id === props.authSession.user.id).length > 0
                                  && props.userProfile.tier === 'Free') ?
                                      <Button variant={'primary'} radius={'full'} onClick={() => setPaywallModalOpen(true)}>
                                          New event
                                      </Button>
                                    :
                                    <Link to={'/create-event'}>
                                        <Button variant={'primary'} radius={'full'}>
                                            New event
                                        </Button>
                                    </Link>
                            )}
                            {location.pathname !== '/' &&
                                <Link to={'/'} className='flex'>
                                    <Button icon={SvgHome} variant={'secondary'} size={'icon'} radius={'full'}/>
                                </Link>
                            }
                            <Link to={'/profile'}>
                                <Button icon={SvgUserCard} variant={'secondary'} size={'icon'} radius={'full'}/>
                            </Link>
                        </div>
                        : null
                    }
                </div>
            </div>
            <CreateEventPaywallModal
              isOpen={paywallModalOpen}
              close={() => setPaywallModalOpen(false)}
            />
        </div>
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
