import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import StripeCheckout from 'components/payment/StripeCheckout';

export default function Page() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [tier, setTier] = useState(null);
  const [loading, setLoading] = useState(true);

  const isValidTier = (tier) => {
    return !!tier && ["enterprise", "pro"].includes(tier.toLowerCase());
  }

  const priceFromTier = (tier) => {
    switch (tier.toLowerCase()) {
      case "enterprise":
        return "price_1PMDdQP0tjU60uObDHjh9Paw";
      case "pro":
        return "price_1PMDcvP0tjU60uOb6ZXueYUR";
      default:
        return null;
    }
  }

  useEffect(() => {
    const tier = searchParams.get("tier");
    if (!isValidTier(tier))
      navigate("/pricing")
    setTier(tier);
    setLoading(false);
  }, [searchParams])

  return loading ? null : (
    <div className="mt-20 mb-20">
      <StripeCheckout priceId={priceFromTier(tier)} />
    </div>
  )
}
